import { makeAutoObservable, toJS } from 'mobx';

const initialState = {
  myList: [],
  streams: [],
  streamOrder: undefined,
  allStreams: undefined,
  skillsData: undefined,
  catalogProviders: undefined,
};

class Store {

  state = initialState;

  constructor() {
    makeAutoObservable(this);
    this.init();
  }

  init() {
    this.state = initialState;
  }

  getState(stateVal) {
    return toJS(this.state[stateVal]);
  }
  setState(stateVal, newState) {
    console.log('newState', newState);
    this.state[stateVal] = newState;
  }
}

export const store = new Store();

